import { AnyObject } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const PAYMENT_STEP_CONFIG_KEY = 'PaymentStep'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const PRZELEWY24 = 'przelewy24'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const GIFT_CARD = 'giftcard'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FREE_PAYMENT = 'free'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultConfig: AnyObject = {
  drivers: ['cashondelivery', 'paynow_gateway', 'banktransfer'],
  icons: {
    cashondelivery: require('./assets/images/przy-odbiorze.svg'),
    paynow_gateway: require('./assets/images/paynow.svg'),
    banktransfer: require('./assets/images/przelew.png')
  },
  shouldReducePaymentMethods: false,
  shippingsPaymentsMap: null,
  shouldDefaultAcceptConsent: false,
  useGiftCardsForPayment: false,
  paymentsOrder: null
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Przelewy24ExcludedMethods: number[] = [154, 178, 227, 238, 239, 147]
