


























































import { Component, Inject as VueInject, Mixins, Watch } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY, logger } from '../../../support'
import { CartItemData, CartTax, OrderData, OrderResponse, PaymentMethodCode } from '../../../contexts'

import { BenefitProgram } from '../../loyalty/contracts/programs'
import { CartAnalyticsMixin } from '../../shared/mixins/cart-analytics.mixin'
import { FullscreenLoader } from '../../shared/molecules/Loader/FullscreenLoader.vue'
import { SidesLayout } from '../../shared/molecules/SidesLayout'

import Bundle from '../organisms/Bundle/Bundle.vue'
import CartSummary from '../organisms/CartSummary/CartSummary.vue'
import { CartCoupons } from '../organisms/CartCoupons'
import { CheckoutOverview } from '../organisms/CheckoutOverview'
import { CircularStepper } from '../molecules/CircularStepper'
import { ORDER_KEY } from '../services/cart'
import { SingleCartItem } from '../organisms/SingleCartItem'
import { Step } from '../molecules/Step'
import { translateToCartItemEnhanced } from '../molecules/CartItem/CartItem.helpers'

import { CheckoutBase } from './base'
import { CheckoutAgreement } from '../contracts'
import { BundleProductsMixin } from '../shared/mixins/bundle-products.mixin'
import { CartBundleItem } from '../../../contexts/checkout/contracts/bundles'
import { RouteName } from '../routes'
import { ToastMixin } from '../../shared/mixins/toast.mixin'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CheckoutTwo>({
  name: 'CheckoutTwo',
  components: {
    Bundle,
    CartCoupons,
    CartSummary,
    FullscreenLoader,
    SidesLayout,
    SingleCartItem,
    Step,
    CircularStepper,
    CheckoutOverview
  },
  mounted () {
    this.pages = this.siteService?.getActiveSiteUrls()
    this.logo = this.siteService.getActiveSite().logo.light
    if (this.programs.includes(BenefitProgram.FriendsAndFamily)) {
      this.logo = this.siteService.getActiveSite().logo.faf
    }

    setTimeout(() => {
      this.verifyIfStepSet()
    }, 0)

    if (!this.cartLoading) {
      this.updateCheckoutFromCart()
    }
  }
})
export class CheckoutTwo extends Mixins(CheckoutBase, CartAnalyticsMixin, BundleProductsMixin, ToastMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  public CheckoutRoutes = RouteName

  public logo: string = ''
  public shippingMethod: number | null = null
  public programs: Array<string> = []
  public cartItemsUnavailabilityCheck: boolean = false
  public displayBackToCart: boolean = false
  public blikErrorMessage: string = ''

  @Watch('cart')
  public async onCartUpdate () {
    if (this.cart) {
      await this.composeBundles(
        this.cart.items.filter((item) => item.isInBundle),
        this.cart
      )

      if (!this.cartItemsUnavailabilityCheck) {
        await this.handleUnavailableCartItemsRedirect()
      }
    }
  }

  public get shouldSendReviewsRequest (): boolean {
    return this.agreements.includes('reviews-request')
  }

  public get bundleItems (): Array<CartBundleItem> {
    return this.$store.getters['checkout/bundles']
  }

  public get regularCartItems (): Array<CartItemData> | undefined {
    if (!this.cart) {
      return
    }
    return this.cart.items.filter((item) => !item.isInBundle)
  }

  public get isPaymentMethodBlik (): boolean {
    return this.cart?.selectedPaymentMethod?.code === PaymentMethodCode.Przelewy24Blik
  }

  public translateToCartItem = translateToCartItemEnhanced

  public lastStep: number = 5

  public getLoyaltyPrograms (programs: Array<string>) {
    this.programs = programs
  }

  public onStep (payload: any): void {
    this.lastStep = payload.lastStep
    if (payload.step === 3) {
      this.shippingMethod = this.cart?.selectedShippingMethod?.price.value || 0
    }
  }

  public async handleUnavailableCartItemsRedirect (): Promise<void> {
    if (!this.cart || this.cart.items.length === 0) {
      return
    }

    try {
      const resolvedItems = await this.productsRepository.loadProductsBySkus(this.cart.items.map(item => item.sku))

      if (!resolvedItems || resolvedItems.length === 0) {
        return
      }

      const isSomeUnavailable = resolvedItems.some(item => {
        return Object.values(item.variants).some(variant => {
          if (!this.cart!.items.map(item => item.product.sku).includes(variant.sku)) {
            return false
          }
          return !variant.isAvailable || variant.sellableQuantity < 1
        })
      })

      if (isSomeUnavailable) {
        await this.$router.push({ name: `checkout.${RouteName.Cart}` })
      }

      this.cartItemsUnavailabilityCheck = true
    } catch (e) {
      logger(e)
    }
  }

  public async placeOrder (): Promise<void> {
    if (!this.cart?.isSet()) {
      return
    }
    try {
      this.eventBus.emit('app:customer_details', {
        emailOffers: this.agreements.includes(CheckoutAgreement.EmailPromotion) ? true : undefined,
        smsOffers: this.agreements.includes(CheckoutAgreement.SmsPromotion) ? true : undefined
      })

      this.eventBus.emit('app:checkout.pre-purchase', this.getPurchasePayload(this.cart))
    } catch (e) {
      logger(e, 'warn')
    }
    this.placingOrder = true

    if (!this.isPaymentMethodBlik) {
      document.body.classList.add('loading-order') // fixme: ukrywa tajemniczy niewylapany wyjatek
    }

    try {
      this.blikErrorMessage = ''
      const response = await this.checkoutService.placeOrder(this.cart.id, this.shouldSendReviewsRequest)
      let tax: number | undefined
      if (this.cart && this.cart.hasOwnProperty('taxes')) {
        tax = this.cart.taxes.reduce((acc: number, tax: CartTax) => {
          return acc + tax.amount.value
        }, 0)
      }

      this.eventBus.emit('app:checkout.purchase', {
        ...this.getPurchasePayload(this.cart ?? null, tax),
        transactionId: response ? response.orderNumber : ''
      })

      await this.afterOrderPlaced(response)

      if (
        this.checkoutPayload && (
          this.agreements.includes(CheckoutAgreement.EmailPromotion) ||
          this.agreements.includes(CheckoutAgreement.SmsPromotion)
        )) {
        // this.eventBus.emit('app:newsletter.subscribe', {
        //   email: this.checkoutPayload.user.email,
        //   name: this.checkoutPayload.user.firstName,
        //   allow_marketing: this.agreements.includes(CheckoutAgreement.EmailPromotion) ? 'true' : 'false',
        //   allow_sms_marketing: this.agreements.includes(CheckoutAgreement.SmsPromotion) ? 'true' : 'false'
        // })
      }
    } catch (e) {
      logger(e, 'warn')
      this.handleError((e as Error).message)
    } finally {
      setTimeout(() => {
        document.body.classList.remove('loading-order')
      }, 1000)

      this.placingOrder = false
    }
  }

  public onIsLoading (value: boolean): void {
    this.isLoading = value
  }

  protected async saveOrderData (order: OrderResponse): Promise<void> {
    if (!this.cart || !this.cart.selectedShippingMethod) {
      throw new Error('Missing or invaild cart')
    }
    // ,
    //   cart: this.cart,
    //     deliveryTime: this.deliveryTime,
    //     applied_coupon_code: this.currentCouponCode ? this.currentCouponCode.code : null
    const orderData: OrderData = {
      ...order,
      cart: this.cart,
      deliveryFrom: '1',
      deliveryTo: '3',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      total: this.$options.filters!.currency(this.cart.getTotalPrice())
    }

    localStorage.setItem(ORDER_KEY, JSON.stringify(orderData))
  }

  @Watch('cart')
  protected initCheckout () {
    this.updateCheckoutFromCart()
  }

  @Watch('programs')
  protected onIsLoggedInUser () {
    if (this.programs.includes(BenefitProgram.FriendsAndFamily)) {
      this.logo = this.siteService.getActiveSite().logo.faf
    }
  }

  protected verifyIfStepSet (): void {
    if (typeof this.$route.query.step !== 'string' || this.$route.query.step.length === 0) {
      this.moveToStep(1, true)
      return
    }

    const stepCandidate: number = parseInt(this.$route.query.step)
    if (stepCandidate < 1 || stepCandidate > this.lastStep) {
      this.moveToStep(1, true)
    }
  }

  protected handleError (message: string): void {
    if (this.isPaymentMethodBlik &&
      message === 'Unable to place order: Transaction has been declined. Please try again later.'
    ) {
      this.blikErrorMessage = String(this.$t('front.checkout.organisms.BlikWhitelabel.invalidCode'))
      return
    }

    if (message === 'Unable to place order: Żądana ilość nie jest dostępna') {
      this.globalError = 'Nie można złożyć zamówienia: Żądana ilość nie jest dostępna.'
    } else if (message === 'Unable to place order: Some of the products are out of stock.') {
      this.globalError = 'Nie można złożyć zamówienia: Niektóre produkty są niedostępne.'
    } else {
      this.globalError = message
    }

    this.showToast(message, 'danger')

    this.displayBackToCart = (message === 'Unable to place order: Żądana ilość nie jest dostępna') ||
      (message === 'Unable to place order: Some of the products are out of stock.') ||
      (message === 'Nie można złożyć zamówienia: Niektóre produkty są niedostępne.')

    if (this.displayBackToCart) {
      this.reloadCart()
    }
  }
}

export default CheckoutTwo
