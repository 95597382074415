












































































































































































































































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { PaymentMethodCode } from '../../../../contexts'
import { Inject, logger } from '../../../../support'
import { StructureConfigurable } from '../../../../support/mixins'

import { ISubscriptionsMixin, SubscriptionsMixin } from '../../../auth/shared'

import { CartModel } from '../../contracts'
import { CheckoutServiceType, ICheckoutService } from '../../services/checkout'

import { shippingIcon } from '../Shippings/Shippings.helpers'
import { Przelewy24PaymentMethodDefinition } from '../PaymentStep/PaymentStep.contracts'
import { paymentIcon } from '../PaymentStep/PaymentStep.helpers'
import {
  PAYMENT_STEP_CONFIG_KEY,
  Przelewy24ExcludedMethods
} from '../PaymentStep/PaymentStep.config'
import { CheckoutOverviewConfig } from './CheckoutOverview.contracts'
import {
  CHECKOUT_OVERVIEW_COMPONENT_CONFIG_MAP,
  CHECKOUT_OVERVIEW_COMPONENT_KEY
} from './CheckoutOverview.config'
import CheckoutOverviewBlik from '../CheckoutOverviewBlik/CheckoutOverviewBlik.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CheckoutOverview>({
  name: 'CheckoutOverview',
  components: { CheckoutOverviewBlik, VClamp: () => import(/* webpackChunkName: "vue-clamp" */ 'vue-clamp') },
  created (): void {
    this.config = this.getComponentConfig(CHECKOUT_OVERVIEW_COMPONENT_KEY, { ...CHECKOUT_OVERVIEW_COMPONENT_CONFIG_MAP })
    this.paymentConfig = this.getComponentConfig(PAYMENT_STEP_CONFIG_KEY)
    this.loadPrzelewy24PaymentMethod()
  }
})
export class CheckoutOverview extends Mixins<StructureConfigurable, ISubscriptionsMixin>(StructureConfigurable, SubscriptionsMixin) {
  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  @Prop({ type: Object, required: true })
  public readonly cart!: CartModel

  @Prop({ type: Object, required: true })
  public readonly pages!: AnyObject

  @Prop({ type: Boolean, required: true })
  public readonly isLoading!: boolean

  @Prop({ type: Number, required: true })
  public readonly lastStep!: number

  @Prop({ type: String, default: '' })
  public readonly blikErrorMessage!: string

  @PropSync('agreements', { type: Array, required: true })
  public _agreements!: string[]

  protected config!: CheckoutOverviewConfig
  protected paymentConfig!: AnyObject

  public availableSteps: number = 5 // including overview
  public selectedPrzelewy24Method: Przelewy24PaymentMethodDefinition | null = null
  protected expandedGroup: string[] = []

  public get possibleAgreements (): Array<string> {
    return this.getConfigProperty('possibleAgreements')
  }

  public get checkAll (): boolean {
    return this.possibleAgreements.every((consent: string) => {
      return this._agreements.includes(consent)
    })
  }

  public set checkAll (checked: boolean) {
    this._agreements = checked ? [...this.possibleAgreements] : []
  }

  public get billingAddress () {
    return this.cart.billingAddress
  }

  public get shippingAddress () {
    return this.cart.shippingAddress
  }

  public get showDoublePayment (): boolean {
    return !!this.cart.selectedPaymentMethod && this.cart.selectedPaymentMethod.code !== 'free'
  }

  public get giftCardImage (): string {
    let image = ''
    try { // todo: wrapped in try-cacth, as it breaks backoffice - fix import!
      image = require('../../../../../../../assets/images/payments/giftcard.png')
    } catch (e) {}

    return image
  }

  public get paymentMethod () {
    if (!this.cart.selectedPaymentMethod) {
      return null
    }

    if (this.cart.selectedPaymentMethod.code === PaymentMethodCode.Przelewy24) {
      if (this.cart.selectedPaymentMethodId > 0) {
        if (Przelewy24ExcludedMethods.includes(this.cart.selectedPaymentMethodId)) {
          return {
            title: this.$t(`front.checkout.organisms.PaymentStep.Przelewy24.${this.cart.selectedPaymentMethodId}.title`),
            image: paymentIcon[this.cart.selectedPaymentMethodId]
          }
        } else {
          return {
            title: this.selectedPrzelewy24Method?.name,
            image: this.selectedPrzelewy24Method?.imgUrl
          }
        }
      }
    }

    return {
      title: this.$t(`front.checkout.organisms.PaymentStep.method.${this.cart.selectedPaymentMethod.code}.title`),
      image: paymentIcon[this.cart.selectedPaymentMethod.code]
    }
  }

  public get shouldReducePaymentMethods (): boolean {
    return this.getConfigProperty<boolean>('shouldReducePaymentMethods', this.paymentConfig)
  }

  public get shippingsPaymentsMap (): Record<string, Array<string>> {
    return this.getConfigProperty<Record<string, Array<string>>>('shippingsPaymentsMap', this.paymentConfig)
  }

  public get isValidPaymentMethod (): boolean {
    let supportedPaymentMethods = this.getConfigProperty<string[]>('drivers', this.paymentConfig)
    const selectedPaymentMethod = this.cart.selectedPaymentMethod?.code ?? ''

    if (this.shouldReducePaymentMethods) {
      const selectedShipping = this.cart.selectedShippingMethod?.methodCode ?? ''

      if (this.shippingsPaymentsMap && typeof this.shippingsPaymentsMap[selectedShipping] !== 'undefined') {
        supportedPaymentMethods = this.shippingsPaymentsMap[selectedShipping]
      }
    }

    return supportedPaymentMethods.indexOf(selectedPaymentMethod) >= 0
  }

  public get isDisabled (): boolean {
    return !this.isValidPaymentMethod
  }

  public get shippingMethod () {
    if (!this.cart.selectedShippingMethod) {
      return null
    }

    return {
      price: this.cart.selectedShippingMethod.price.value,
      title:
        this.$t(`front.checkout.organisms.ShippingStep.method.${this.cart.selectedShippingMethod.methodCode}.title`),
      image: shippingIcon[this.cart.selectedShippingMethod.methodCode]
    }
  }

  public get isPaymentMethodBlik (): boolean {
    return this.cart.selectedPaymentMethod?.code === PaymentMethodCode.Przelewy24Blik
  }

  public isExpanded (key: string): boolean {
    return this.expandedGroup.includes(key)
  }

  public expand (key: string): void {
    if (this.isExpanded(key)) {
      this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1)
    } else {
      this.expandedGroup.push(key)
    }
  }

  /**
   * Fires step jump.
   */
  public jumpToStep (step: number): void {
    if (this.availableSteps > this.lastStep) {
      const diff = this.availableSteps - this.lastStep
      step = step - diff
    }
    this.$router.replace(
      { name: `${this.$route.name}`, query: { step: `${step}` } }
    )
  }

  /**
   * Handles placing order.
   */
  public async onOrder (): Promise<void> {
    this.$emit('onOrder')
  }

  protected async loadPrzelewy24PaymentMethod (): Promise<void> {
    if (this.cart.selectedPaymentMethod!.code !== PaymentMethodCode.Przelewy24) {
      return
    }

    try {
      const methods = await this.checkoutService.loadPaymentMethods(this.cart.total.value)
      const method = methods.find(m => m.id === this.cart.selectedPaymentMethodId)
      this.selectedPrzelewy24Method = method ?? null
    } catch (e) {
      logger(e, 'error')
    }
  }

  public async onBlikCode (code?: string) {
    if (!code) {
      return
    }

    const transactionData = await this.checkoutService.registerPrzelewy24BlikTransaction({
      cartId: this.cart.id,
      saveAlias: false
    })

    await this.checkoutService.setPaymentMethod(this.cart.id, {
      code: PaymentMethodCode.Przelewy24Blik
    }, {
      type: PaymentMethodCode.Przelewy24Blik,
      data: {
        blikCode: code,
        regulationAccept: true,
        token: transactionData.token,
        sessionId: transactionData.transaction
      }
    })
  }
}

export default CheckoutOverview
