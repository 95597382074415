import { AnyObject } from '@movecloser/front-core'
import { AsyncComponent } from 'vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const CIRCULAR_STEPPER_COMPONENT_KEY = 'CircularStepperComponent'

/**
 * TODO: Order is important!.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultComponentConfig: AnyObject = {
  steps: [
    {
      type: 'data',
      version: 'general'
    },
    {
      type: 'benefits',
      version: 'general'
    },
    {
      type: 'shipping',
      version: 'general'
    },
    {
      type: 'payments',
      version: 'general'
    }
  ],
  considerOtherStepsWhenDataStepIsSet: false,
  considerOtherStepsWhenUserRegisters: false,
  considerOtherStepsWhenLoyalty: false
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const stepsComponentConstructor: Record<string, Record<string, AsyncComponent>> = {
  data: {
    general: () => import(/* webpackChunkName: "checkout/CustomerDetailsStep" */ '../../organisms/CustomerDetailsStep/CustomerDetailsStep.vue'),
    simplified: () => import(/* webpackChunkName: "checkout/CustomerDetailsStepSimplified" */ '../../organisms/CustomerDetailsStep/versions/CustomerDetailsStepSimplified.vue')
  },
  benefits: {
    general: () => import(/* webpackChunkName: "checkout/BenefitsStep" */ '../../organisms/BenefitsStep/BenefitsStep.vue'),
    simplified: () => import(/* webpackChunkName: "checkout/BenefitsStep" */ '../../organisms/BenefitsStep/BenefitsStep.vue')
  },
  flightData: {
    general: () => import(/* webpackChunkName: "checkout/FlightDataStep" */ '../../organisms/FlightDataStep/FlightDataStep.vue')
  },
  shipping: {
    general: () => import(/* webpackChunkName: "checkout/DeliveryDetailsStep" */ '../../organisms/DeliveryDetailsStep/DeliveryDetailsStep.vue'),
    simplified: () => import(/* webpackChunkName: "checkout/DeliveryDetailsStep" */ '../../organisms/DeliveryDetailsStep/DeliveryDetailsStep.vue')
  },
  payments: {
    general: () => import(/* webpackChunkName: "checkout/PaymentStepTwo" */ '../../organisms/PaymentStepTwo/PaymentStepTwo.vue'),
    simplified: () => import(/* webpackChunkName: "checkout/PaymentStepTwo" */ '../../organisms/PaymentStepTwo/PaymentStepTwo.vue')
  }
}
