import { CheckoutOverviewConfig } from './CheckoutOverview.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHECKOUT_OVERVIEW_COMPONENT_KEY = 'CheckoutOverviewComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHECKOUT_OVERVIEW_COMPONENT_CONFIG_MAP: CheckoutOverviewConfig = {
  possibleAgreements: ['email-promotion', 'sms-promotion']
}
